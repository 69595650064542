<template>
  <el-main>
    <div style="margin-bottom: 15px">
      <el-button type="primary" class="el-icon-plus" size="small" @click="add">添加素材</el-button>
      <el-button type="primary" class="el-icon-plus" size="small" @click="addClassify">添加类别</el-button>
      <el-button type="primary" size="small" @click="showEditClassify = !0">编辑类别</el-button>
    </div>
    <el-form class="el-form-search" label-width="90px">
      <el-form-item label="素材对象：">
        <el-select v-model="searchForm.object_type" size="small" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="代理商素材" :value="0"></el-option>
          <el-option label="发展商素材" :value="1"></el-option>
          <el-option label="商家素材" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="素材内容：">
        <el-input size="small" v-model="searchForm.content" placeholder="请输入素材内容"></el-input>
      </el-form-item>
      <el-form-item label="素材类别：">
        <el-select v-model="searchForm.cate_id" size="small" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option v-for="(item, index) in classifyList" :key="index" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="15px">
        <el-button type="primary" size="small" @click="search">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="object_type" label="素材对象" align="center">
        <template v-slot="{ row }">
          {{ row.object_type == 1 ? '发展商素材' : row.object_type == 2 ? '商家素材' : '代理商素材' }}
        </template>
      </el-table-column>
      <el-table-column prop="cate_name" label="素材类别" align="center"></el-table-column>
      <el-table-column prop="content" label="素材内容" align="center"></el-table-column>
      <el-table-column prop="image" label="素材图片" align="center">
        <template v-slot="{ row }">
          <el-button type="text" @click="seeImage(row)">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right">
        <template v-slot="{ row }">
          <el-button @click="edit(row)" type="text">编辑</el-button>
          <el-button @click="del(row)" type="text">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="添加/编辑" :visible.sync="showAdd" width="650px">
      <el-form ref="form1" :model="addForm" :rules="rules" label-width="100px">
        <el-form-item label="素材对象：" prop="object_type">
          <el-select v-model="addForm.object_type" placeholder="请选择">
            <el-option label="代理商素材" :value="0"></el-option>
            <el-option label="发展商素材" :value="1"></el-option>
            <el-option label="商家素材" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="素材类别：" prop="cate_id">
          <el-select v-model="addForm.cate_id" placeholder="请选择">
            <el-option v-for="(item, index) in classifyList" :key="index" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="素材内容：" prop="content">
          <el-input v-model="addForm.content" :rows="5" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="素材图片：" prop="image">
          <ReadyUploadSource
            @getSource="getImg"
            @changeOrder="changeOrder"
            @removeThis="removeImageThis"
            :isMany="true"
            :manyPath="addForm.image"
            :isManyMax="10"
            :maxSize="1024 * 1024"
          ></ReadyUploadSource>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAdd = !1">取 消</el-button>
        <el-button type="primary" @click="confirmAdd">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="添加类别" :visible.sync="showAddClassify" width="650px">
      <el-form ref="form" :model="addClassifyForm" :rules="rulesClassify" label-width="100px">
        <el-form-item label="分类名称：" prop="name">
          <el-input v-model="addClassifyForm.name"></el-input>
        </el-form-item>
        <el-form-item label="排序：" prop="displayorder">
          <el-input v-model="addClassifyForm.displayorder"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddClassify = !1">取 消</el-button>
        <el-button type="primary" @click="confirmAddClassify">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="编辑类别" :visible.sync="showEditClassify" width="750px">
      <el-table :data="classifyList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="name" label="分类名称" align="center">
          <template v-slot="{ row }">
            <el-input v-if="row.is_edit" v-model="row.name" size="small"></el-input>
            <span v-else>{{ row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="displayorder" label="组内权重" align="center">
          <template v-slot="{ row }">
            <el-input v-if="row.is_edit" v-model="row.displayorder" size="small"></el-input>
            <span v-else>{{ row.displayorder }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" fixed="right">
          <template v-slot="{ row }">
            <el-button @click="editClassify(row)" type="text">{{ row.is_edit ? '确定' : '编辑' }}</el-button>
            <el-button @click="delClassify(row)" type="text">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Paging :total="classify_total_number" :page="page" :pageNum="rows" @updatePageNum="classsifyUpdateData"></Paging>
    </el-dialog>
    <el-dialog title="查看" :visible.sync="showImage" width="650px">
      <div class="see-image">
        <el-image v-for="(item, index) in image" fit="cover" :key="index" :src="item" :preview-src-list="image"></el-image>
      </div>
    </el-dialog>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import ReadyUploadSource from '@/components/readyUploadSource';
export default {
  components: {
    Paging,
    ReadyUploadSource,
  },
  data() {
    return {
      searchForm: {
        page: 1,
        rows: 10,
        cate_id: '',
        content: '',
        object_type: '',
      },
      addClassifyForm: {
        name: '',
        displayorder: '',
      },
      addForm: {
        cate_id: '',
        content: '',
        image: [],
        object_type: '',
      },
      rules: {
        cate_id: { required: true, message: '请选择素材类别', trigger: 'blur' },
        content: { required: true, message: '请填写素材内容', trigger: 'blur' },
        image: { required: true, type: 'array', message: '请添加素材图片', trigger: 'blur' },
        object_type: { required: true, message: '请选择素材对象', trigger: 'blur' },
      },
      rulesClassify: {
        name: { required: true, message: '请填写分类名称', trigger: 'blur' },
        displayorder: { required: true, message: '请填写排序', trigger: 'blur' },
      },
      image: [],
      page: 1,
      rows: 10,
      total_number: 0,
      classify_total_number: 0,
      classifyList: [],
      list: [],
      showAdd: !1,
      showImage: !1,
      showAddClassify: !1,
      showEditClassify: !1,
    };
  },
  created() {
    this.getClassifyList();
    this.getList();
  },
  methods: {
    search() {
      this.searchForm.page = 1;
      this.getList();
    },
    clearSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        cate_id: '',
        content: '',
        object_type: '',
      };
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    seeImage(row) {
      this.image = row.image;
      this.showImage = !0;
    },
    add() {
      this.showAdd = !0;
      this.addForm = {
        cate_id: '',
        content: '',
        image: [],
        object_type: '',
      };
    },
    edit(row) {
      this.addForm.cate_id = row.cate_id;
      this.addForm.content = row.content;
      this.addForm.image = row.image;
      this.addForm.object_type = row.object_type;
      this.addForm.id = row.id;
      this.showAdd = !0;
    },
    del(row) {
      this.$confirm('请确认是否删除素材？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$axios.post(this.$api.serviceProvider.delMaterial, { id: row.id }).then(res => {
          if (res.code == 0) {
            this.getList();
            this.$message.success('删除成功');
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    confirmAdd() {
      this.$refs.form1.validate(valid => {
        if (valid) {
          this.$axios.post(this.addForm.id ? this.$api.serviceProvider.editMaterial : this.$api.serviceProvider.addMaterial, this.addForm).then(res => {
            if (res.code == 0) {
              this.showAdd = !1;
              this.getList();
              this.$message.success(this.addForm.id ? '编辑成功' : '添加成功');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    getImg(val) {
      for (let i in val) {
        this.addForm.image.push(val[i].path);
      }
    },
    changeOrder(list) {
      this.addForm.image = list;
    },
    removeImageThis(index) {
      this.addForm.image.splice(index, 1);
    },
    classsifyUpdateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getClassifyList();
    },
    editClassify(row) {
      if (row.is_edit) {
        this.$axios
          .post(this.$api.serviceProvider.editMaterialCate, {
            id: row.id,
            name: row.name,
            displayorder: row.displayorder,
          })
          .then(res => {
            if (res.code == 0) {
              row.is_edit = !1;
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        row.is_edit = !0;
      }
    },
    delClassify(row) {
      this.$axios
        .post(this.$api.serviceProvider.delMaterialCate, {
          id: row.id,
        })
        .then(res => {
          if (res.code == 0) {
            row.is_edit = !1;
            this.getClassifyList();
            this.$message.success('删除成功');
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    addClassify() {
      this.showAddClassify = !0;
      this.addClassifyForm = {
        name: '',
        displayorder: '',
      };
    },
    confirmAddClassify() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$axios.post(this.$api.serviceProvider.addMaterialCate, this.addClassifyForm).then(res => {
            if (res.code == 0) {
              this.$message.success('添加成功');
              this.getClassifyList();
              this.showAddClassify = !1;
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    getClassifyList() {
      this.$axios
        .post(this.$api.serviceProvider.materialCateList, {
          page: this.page,
          rows: this.rows,
        })
        .then(res => {
          if (res.code == 0) {
            let list = res.result.list;
            list.map(item => (item.is_edit = !1));
            this.classifyList = list;
            this.classify_total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getList() {
      this.$axios.post(this.$api.serviceProvider.materialList, this.searchForm).then(res => {
        if (res.code == 0) {
          res.result.list.map(item => {
            item.image = item.image ? JSON.parse(item.image) : [];
          });
          this.list = res.result.list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .see-image {
    .el-image {
      width: 140px;
      height: 90px;
      margin-right: 10px;
    }
  }
}
</style>
